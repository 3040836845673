import { useEffect, useState } from 'react';
import '../../styles/box.scss';
import './Challenge.scss';
import { IdentityService } from '../../services/Identity.Service';
import config from '../../config';
import Prompt from './components/Prompt';
import TriggerButton from '../../components/Buttons/TriggerButton';

export default function Challenge() {
  const [flow, setFlow] = useState<string | null>(new URLSearchParams(window.location.search).get('flow'));
  const token = new URLSearchParams(window.location.search).get('t');
  const [loading, setLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<number[]>([]);

  useEffect(() => {
    document.title = 'BraveGen | Challenge';

    const lookupToken = async () => {
      setLoading(true);

      //Fetch
      await IdentityService.getChallenge(`${token}`)
        .then((a) => {
          if (a.scope === 0) {
            //Unknown - Error
            setFlow('0');
            setLoading(false);
          } else if (a.scope === 1) {
            //None Required
            localStorage.setItem('type', `${a.type}`);
            redeemSession();
          } else if (a.scope === 2) {
            //MFA required.
            localStorage.setItem('type', `${a.type}`);
            setQuestions(a.questions);
            setFlow('2');
            setLoading(false);
          }
        })
        .catch((b) => {
          setFlow('0');
          setLoading(false);
        });
    };
    const redeemSession = async () => {
      await IdentityService.verifyChallenge({ token: `${token}`, code: '', answers: [] })
        .then((a) => {
          //Redirect to Hub
          const ref = localStorage.getItem('ref');
          const type = localStorage.getItem('type');
          if (ref && type === '2') {
            window.location.href = `${ref}/federated?t=${a.token}`;
          } else {
            window.location.href = `${config.hub}/federated?t=${a.token}`;
          }
        })
        .catch((b) => {
          setFlow('0');
          setLoading(false);
        });
    };

    if (flow === '2') {
      //Verification.
      lookupToken();
    } else {
      //Errors
      setLoading(false);
    }
  }, [flow, token]);

  return (
    <>
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
          <img src="/resources/Lion-Charcoal.png" className="lion" alt="BraveGen" />
        </div>
      ) : (
        <div className="box">
          <img src="/resources/Lion-Full.png" className="animate" alt="BraveGen" />
          <div className="inner animate processing">
            {flow === '0' && (
              <>
                <span className="left">
                  We're sorry, it seems we're having some trouble processing your request.
                  <br />
                  <br />
                  <strong>Possible resolutions:</strong>
                  <br />
                  Try restarting your browser and login again. If this still persist, please contact BraveGen.
                </span>
                <TriggerButton
                  label="Try again"
                  theme="default"
                  disabled={false}
                  onClick={() => {
                    //Purge state
                    IdentityService.expungeToken();
                    const ref = localStorage.getItem('ref');
                    if (ref) {
                      window.location.href = `${ref}/SignOut`;
                    } else {
                      window.location.href = `${config.hub}/SignOut`;
                    }
                  }}></TriggerButton>
              </>
            )}
            {flow === '1' && (
              <span className="left">
                We're sorry, it seems we're unable to identify you at this time.
                <br />
                <br />
                <strong>Possible resolutions:</strong>
                <br />
                Ask your BraveGen administrator to add your email address to the account and try again.
              </span>
            )}
            {flow === '2' && <Prompt token={`${token}`} questions={questions} />}
          </div>
        </div>
      )}
    </>
  );
}
