import './App.scss';
import { useIdentity } from './state/Identity.Hook';
import { IdentityContext } from './state/Identity.Context';
import ViewRoutes from './ViewRoutes';

function App() {
  const identity = useIdentity();

  return (
    <>
      {!identity.loading ? (
        <IdentityContext.Provider value={identity}>
          <ViewRoutes />
        </IdentityContext.Provider>
      ) : (
        <div className="loading">
          <div className="spinner"></div>
          <img src="/resources/Lion-Charcoal.png" className="lion" alt="BraveGen" />
        </div>
      )}

      <div className="grid">
        <img src="/resources/grid.png" alt="BraveGen grid" />
      </div>
    </>
  );
}

export default App;
